import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ProjectImages from "../components/ProjectImages";
import Newsletter from "../components/Newsletter";
import Img from "gatsby-image";

import social_image from "../images/social.png";

import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

function AboutPage() {
  const projImages = ProjectImages();
  const { t } = useTranslation();
  return (
    <Layout>
      <SEO
        keywords={[`projects`, "Diego Valle", "Diego Valle-Jones"]}
        title={t("Projects")}
        description={t("Visualizations, programming, web apps and lots of open data by Diego Valle")}
        image={social_image}
      />
      <main className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full">
        <section className="text-center">
          <h1>
            <Trans>Projects</Trans>
          </h1>
        </section>
        <section className="text-black">
          <p className="py-6">
            <Trans i18nKey="projects_text">
              I've created many data science and visualization projects that
              will ignite your curiosity and deepen your understanding of the
              world of data. From uncovering hidden patterns in massive datasets
              to creating stunning visual representations of complex
              information, this collection showcases the power of data-driven
              insights. Get ready to be captivated, inspired, and amazed.
            </Trans>
          </p>

          <div className="border-t">
            <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full  border-r border-l">
              <div className="flex mb-4 h-full  flex-wrap">
                <div className="w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
                  <h2>elcri.men</h2>
                  <p>
                    <Trans i18nKey="projects_elcrimen">
                      Up-to-date crime information at the state and municipality
                      levels for all of Mexico{" "}
                      <a href="https://elcri.men/en/" rel="me">
                        https://elcri.men/en/
                      </a>{" "}
                      and Spanish{" "}
                      <a href="https://elcri.men" rel="me">
                        https://elcri.men
                      </a>
                    </Trans>
                  </p>
                </div>
                <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
                  <a
                    href="https://elcri.men/en/"
                    aria-label="elcri.men screenshot"
                  >
                    <Img
                      fluid={projImages.elcrimen.childImageSharp.fluid}
                      sizes={{
                        ...projImages.elcrimen.childImageSharp.fluid,
                        aspectRatio: 1,
                      }}
                      imgStyle={{ overflow: "hidden" }}
                      loading="eager"
                      className="rounded-full border"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className=" border">
            <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full   border-r border-l">
              <div className="flex mb-4 h-full  flex-wrap">
                <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
                  <a
                    href="https://hoyodecrimen.com/en/"
                    aria-label="hoyodecrimen.com screenshot"
                  >
                    <Img
                      fluid={projImages.hoyodecrimen.childImageSharp.fluid}
                      sizes={{
                        ...projImages.hoyodecrimen.childImageSharp.fluid,
                        aspectRatio: 1,
                      }}
                      loading="lazy"
                      imgStyle={{ overflow: "hidden" }}
                      className="rounded-full border"
                    />
                  </a>
                </div>
                <div className="w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
                  <h2>hoyodecrimen.com</h2>
                  <p>
                    <Trans i18nKey="projects_hoyo">
                      A web app and{" "}
                      <a href="https://hoyodecrimen.com/api/">API</a> providing
                      crime information for Mexico City{" "}
                      <a href="https://hoyodecrimen.com" rel="me">
                        https://hoyodecrimen.com
                      </a>{" "}
                      and in English{" "}
                      <a href="https://hoyodecrimen.com/en/" rel="me">
                        https://hoyodecrimen.com/en/
                      </a>
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full  border-r border-l">
              <div className="flex mb-4 h-full  flex-wrap">
                <div className="w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
                  <h2>mxmaps</h2>
                  <p>
                    <Trans i18nKey="projects_mxmaps">
                      An R package for creating choropleths of Mexico{" "}
                      <a href="https://www.diegovalle.net/mxmaps/">
                        https://www.diegovalle.net/mxmaps/
                      </a>
                    </Trans>
                  </p>
                </div>
                <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
                  <a
                    href="https://www.diegovalle.net/mxmaps/"
                    aria-label="mxmaps screenshot"
                  >
                    <Img
                      fluid={projImages.mxmaps.childImageSharp.fluid}
                      sizes={{
                        ...projImages.mxmaps.childImageSharp.fluid,
                        aspectRatio: 1,
                      }}
                      loading="lazy"
                      imgStyle={{ overflow: "hidden" }}
                      className="rounded-full border"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="border">
            <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full  border-r border-l">
              <div className="flex mb-4 h-full  flex-wrap">
                <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
                  <a
                    href="https://hoyodesmog.diegovalle.net/"
                    aria-label="hoyodesmog screenshot"
                  >
                    <Img
                      fluid={projImages.hoyodesmog.childImageSharp.fluid}
                      sizes={{
                        ...projImages.hoyodesmog.childImageSharp.fluid,
                        aspectRatio: 1,
                      }}
                      imgStyle={{ overflow: "hidden" }}
                      loading="lazy"
                      className="rounded-full border"
                    />
                  </a>
                </div>
                <div className="w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
                  <h2>hoyodesmog</h2>
                  <p>
                    <Trans i18nKey="projects_smog">
                      A web app and
                      <a href="https://hoyodesmog.diegovalle.net/aire.zmvm.html">
                        r package
                      </a>
                      providing real-time pollution data for Mexico City{" "}
                      <a href="https://hoyodesmog.diegovalle.net/es/">
                        https://hoyodesmog.diegovalle.net/es/
                      </a>
                      , also in English
                      <a href="https://hoyodesmog.diegovalle.net/">
                        https://hoyodesmog.diegovalle.net/
                      </a>
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full  border-r border-l">
              <div className="flex mb-4 h-full  flex-wrap">
                <div className="w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
                  <h2>mxmortalitydb</h2>
                  <p>
                    <Trans i18nKey="projects_mxmortalitydb">
                      An R package with all injury intent deaths in Mexico since
                      2004{" "}
                      <a href="https://github.com/diegovalle/mxmortalitydb">
                        https://github.com/diegovalle/mxmortalitydb
                      </a>
                    </Trans>
                  </p>
                </div>
                <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
                  <a
                    href="https://github.com/diegovalle/mxmortalitydb"
                    aria-label="mxmortalitydb screenshot"
                  >
                    <Img
                      fluid={projImages.mxmortalitydb.childImageSharp.fluid}
                      sizes={{
                        ...projImages.mxmortalitydb.childImageSharp.fluid,
                        aspectRatio: 1,
                      }}
                      imgStyle={{ overflow: "hidden" }}
                      loading="lazy"
                      className="rounded-full border"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="border">
            <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full  border-r border-l">
              <div className="flex mb-4 h-full  flex-wrap">
                <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
                  <a
                    href="https://hoyodesmog.diegovalle.net/rsinaica/"
                    aria-label="rsinaica screenshot"
                  >
                    <Img
                      fluid={projImages.rsinaica.childImageSharp.fluid}
                      sizes={{
                        ...projImages.rsinaica.childImageSharp.fluid,
                        aspectRatio: 1,
                      }}
                      imgStyle={{ overflow: "hidden" }}
                      loading="lazy"
                      className="rounded-full border"
                    />
                  </a>
                </div>
                <div className="w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
                  <h2>rsinaica</h2>
                  <p>
                    <Trans i18nKey="projects_rsinaica">
                      Easy-to-use functions for downloading air quality data
                      from the Mexican National Air Quality Information System
                      (SINAICA). With this R package you can download pollution
                      and meteorological parameters from the more than a hundred
                      monitoring stations located throughout Mexico.
                      <br />
                      <a href="https://hoyodesmog.diegovalle.net/rsinaica/">
                        https://hoyodesmog.diegovalle.net/rsinaica/
                      </a>
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full  border-r border-l">
              <div className="flex mb-4 h-full  flex-wrap">
                <div className="w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
                  <h2>
                    <Trans>Analysis of the UNAM's admission exam</Trans>
                  </h2>
                  <p>
                    <Trans i18nKey="projects_unam">
                      <a href="http://blog.diegovalle.net/2014/04/analisis-exam-de-admision-unam.html">
                        Blog post
                      </a>
                    </Trans>
                  </p>
                </div>
                <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
                  <a
                    href="http://blog.diegovalle.net/2014/04/analisis-exam-de-admision-unam.html"
                    aria-label="UNAM screenshot"
                  >
                    <Img
                      fluid={projImages.unam.childImageSharp.fluid}
                      sizes={{
                        ...projImages.unam.childImageSharp.fluid,
                        aspectRatio: 1,
                      }}
                      imgStyle={{ overflow: "hidden" }}
                      loading="lazy"
                      className="rounded-full border"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="border">
            <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full  border-r border-l">
              <div className="flex mb-4 h-full  flex-wrap">
                <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
                  <a
                    href="http://diegovalle.github.io/tiger-mom-unam/crosslet.html"
                    aria-label="comipems screenshot"
                  >
                    <Img
                      fluid={projImages.comipems.childImageSharp.fluid}
                      sizes={{
                        ...projImages.comipems.childImageSharp.fluid,
                        aspectRatio: 1,
                      }}
                      imgStyle={{ overflow: "hidden" }}
                      loading="lazy"
                      className="rounded-full border"
                    />
                  </a>
                </div>
                <div className="w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
                  <h2>
                    <Trans>
                      Analysis of public high school admission scores in Mexico
                      City
                    </Trans>
                  </h2>
                  <p>
                    <Trans i18nKey="projects_high">
                      <a href="http://diegovalle.github.io/tiger-mom-unam/crosslet.html">
                        Interactive map
                      </a>{" "}
                      and{" "}
                      <a href="http://blog.diegovalle.net/2013/07/the-best-high-schools-in-mexico-city.html">
                        blog post
                      </a>
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full  border-r border-l">
              <div className="flex mb-4 h-full  flex-wrap">
                <div className="w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
                  <h2>
                    <Trans>
                      Statistical analysis and visualization of the drug war in
                      Mexico
                    </Trans>
                  </h2>
                  <p>
                    <Trans i18nKey="projects_drugwar">
                      Lots of charts and information on the{" "}
                      <a href="http://blog.diegovalle.net/2010/06/statistical-analysis-and-visualization.html">
                        Mexican Drug War
                      </a>
                    </Trans>
                  </p>
                </div>
                <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
                  <a
                    href="http://blog.diegovalle.net/2010/06/statistical-analysis-and-visualization.html"
                    aria-label="drug war screenshot"
                  >
                    <Img
                      fluid={projImages.mex.childImageSharp.fluid}
                      sizes={{
                        ...projImages.mex.childImageSharp.fluid,
                        aspectRatio: 1,
                      }}
                      imgStyle={{ overflow: "hidden" }}
                      loading="lazy"
                      className="rounded-full border"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="border">
            <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full  border-r border-l">
              <div className="flex mb-4 h-full  flex-wrap">
                <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
                  <a
                    href="http://blog.diegovalle.net/2010/12/some-problems-with-mexican-mortality.html"
                    aria-label="acteal screenshot"
                  >
                    <Img
                      fluid={projImages.acteal.childImageSharp.fluid}
                      sizes={{
                        ...projImages.acteal.childImageSharp.fluid,
                        aspectRatio: 1,
                      }}
                      imgStyle={{ overflow: "hidden" }}
                      loading="lazy"
                      className="rounded-full border"
                    />
                  </a>
                </div>
                <div className="w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
                  <h2>
                    <Trans>Errors in the Mexican mortality database</Trans>
                  </h2>
                  <p>
                    <Trans i18nKey="projects_acteal">
                      The Acteal Massacre is recorded as an accident in the
                      Mexican mortality database{" "}
                      <a href="http://blog.diegovalle.net/2010/12/some-problems-with-mexican-mortality.html">
                        blog post
                      </a>
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full  border-r border-l">
              <div className="flex mb-4 h-full  flex-wrap">
                <div className="w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
                  <h2><Trans>Poll of polls</Trans></h2>
                  <p>
                  <Trans i18nKey="projects_poll">
                    Interactive polls of polls of the 2012 Mexican presidential
                    election made with d3, R and JAGS{" "}
                    <a href="http://www.diegovalle.net/electometer">link</a>
                    </Trans>
                  </p>
                </div>
                <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
                  <a
                    href="https://www.diegovalle.net/maps/election2015/election2015.html"
                    aria-label="election screenshot"
                  >
                    <Img
                      fluid={projImages.election2015.childImageSharp.fluid}
                      sizes={{
                        ...projImages.election2015.childImageSharp.fluid,
                        aspectRatio: 1,
                      }}
                      imgStyle={{ overflow: "hidden" }}
                      loading="lazy"
                      className="rounded-full border"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="border">
            <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full  border-r border-l">
              <div className="flex mb-4 h-full  flex-wrap">
                <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
                  <a
                    href="http://blog.diegovalle.net/2012/12/mexicos-drug-war-63000-extra-deaths-in.html"
                    aria-label="63,000 extra deaths screenshot"
                  >
                    <Img
                      fluid={projImages.extra.childImageSharp.fluid}
                      sizes={{
                        ...projImages.extra.childImageSharp.fluid,
                        aspectRatio: 1,
                      }}
                      imgStyle={{ overflow: "hidden" }}
                      loading="lazy"
                      className="rounded-full border"
                    />
                  </a>
                </div>
                <div className="w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
                  <h2><Trans>Mexico's Drug War: 63,000 extra deaths in 6 years</Trans></h2>
                  <p>
                  <Trans i18nKey="projects_excess">
                    There were about 63,000 excess deaths from December 2006 to
                    December 2012{" "}
                    <a href="http://blog.diegovalle.net/2012/12/mexicos-drug-war-63000-extra-deaths-in.html">
                      blog post
                    </a>
                    </Trans>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="flex flex-col flex-1 md:justify-center max-w-4xl mx-auto px-4 py-8 md:p-8 w-full  border-r border-l">
              <div className="flex mb-4 h-full  flex-wrap">
                <div className="w-full sm:w-full md:w-full lg:w-2/3 xl:w-2/3">
                  <h2><Trans>Excess firearm accidents in Sinaloa</Trans></h2>
                  <p>
                  <Trans i18nKey="projects_sinaloa">
                    The state of Sinaloa had a lot of firearm accidents during
                    the period drug war-related deaths exceeded total homicides{" "}
                    <a href="http://blog.diegovalle.net/2011/11/how-mexican-state-ended-up-with-more.html">
                      blog post
                    </a>
                    </Trans>
                  </p>
                </div>
                <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3">
                  <a
                    href="http://blog.diegovalle.net/2011/11/how-mexican-state-ended-up-with-more.html"
                    aria-label="firearm accidents screenshot"
                  >
                    <Img
                      fluid={projImages.sinaloa.childImageSharp.fluid}
                      sizes={{
                        ...projImages.sinaloa.childImageSharp.fluid,
                        aspectRatio: 1,
                      }}
                      imgStyle={{ overflow: "hidden" }}
                      loading="lazy"
                      className="rounded-full border"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <hr className="pt-4" />

        <section>
          <h2>Datasets</h2>

          <p>
            <Trans i18nKey="datasets">If you need a dataset you saw in my blog that is not listed here
            send me an <a href="http://www.diegovalle.net/contact">email</a>.
            I'm sure I have it lying around somewhere</Trans>
          </p>
        </section>
        <Newsletter />
      </main>
    </Layout>
  );
}

export default AboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "projects", "header", "newsletter"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
