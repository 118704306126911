import {useStaticQuery, graphql} from 'gatsby';

const ProjectImages = () => {
  const data = useStaticQuery (graphql`
query projQuery {
  
  elcrimen:file(relativePath: {eq: "elcri.men.png"}) {
    childImageSharp {
      fluid(maxWidth: 400, quality: 90, srcSetBreakpoints: [250, 300, 350, 400]) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }

  hoyodecrimen:file(relativePath: {eq: "hoyodecrimen.com.v2.png"}) {
    childImageSharp {
      fluid(maxWidth: 400, quality: 85, srcSetBreakpoints: [250, 300, 350, 400]) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }

  hoyodesmog:file(relativePath: {eq: "fulls/hoyodesmog.png"}) {
    childImageSharp {
      fluid(maxWidth: 400, quality: 90, srcSetBreakpoints: [250, 300, 350, 400]) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }

    mxmaps:file(relativePath: {eq: "mxmaps.png"}) {
    childImageSharp {
      fluid(maxWidth: 400, quality: 90, srcSetBreakpoints: [250, 300, 350, 400]) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }

  mxmortalitydb:file(relativePath: {eq: "mxmortalitydb.png"}) {
    childImageSharp {
      fluid(maxWidth: 400, quality: 90, srcSetBreakpoints: [250, 300, 350, 400]) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }

  rsinaica:file(relativePath: {eq: "rsinaica.png"}) {
    childImageSharp {
      fluid(maxWidth: 400, quality: 90, srcSetBreakpoints: [250, 300, 350, 400]) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }


    election2015:file(relativePath: {eq: "fulls/election2015.png"}) {
    childImageSharp {
      fluid(maxWidth: 400, quality: 90, srcSetBreakpoints: [250, 300, 350, 400]) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }

    unam:file(relativePath: {eq: "fulls/unam.png"}) {
    childImageSharp {
      fluid(maxWidth: 400, quality: 90, srcSetBreakpoints: [250, 300, 350, 400]) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }

    comipems:file(relativePath: {eq: "fulls/comipems.png"}) {
    childImageSharp {
      fluid(maxWidth: 400, quality: 90, srcSetBreakpoints: [250, 300, 350, 400]) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }

    mex:file(relativePath: {eq: "fulls/mex.png"}) {
    childImageSharp {
      fluid(maxWidth: 400, quality: 90, srcSetBreakpoints: [250, 300, 350, 400]) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }
   
    acteal:file(relativePath: {eq: "fulls/acteal.png"}) {
    childImageSharp {
      fluid(maxWidth: 400, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }   
    extra:file(relativePath: {eq: "fulls/mx_excess_deaths.png"}) {
    childImageSharp {
      fluid(maxWidth: 400, quality: 90, srcSetBreakpoints: [250, 300, 350, 400]) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }

      sinaloa:file(relativePath: {eq: "fulls/sinaloa_discrepancy.png"}) {
    childImageSharp {
      fluid(maxWidth: 400, quality: 90, srcSetBreakpoints: [250, 300, 350, 400]) {
        ...GatsbyImageSharpFluid_withWebp
              originalName
              originalImg
      }
    }
  }
  
}
`);
  return data;
};

export default ProjectImages;
